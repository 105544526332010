<template>
	<div>
		<TableList 
            :form_data_seo.sync="form_data_seo" 
            :columns="columns" 
            :scroll="{x: false}" 
            :get_table_list="this.get_table_list" 
            :is_page="true" 
            ref="list" 
            rowKey="id" 
            :submit_preprocessing="submit_preprocessing" 
            @changeStatus="changeListStatNum"
        >

            <template slot="form_after">
				<div class="page_content">
					<a-row type="flex">
						<a-col style="flex:1" v-for="item in statInfo" :key="item.name">
							<a-statistic :title="item.name" :value="item.value"></a-statistic>
						</a-col>
					</a-row>
				</div>
			</template>

			<template slot="right_btn">
				<!-- <export-table v-if="this.$store.state.page_auth.export" url="/manage/crm.report/orderScheduleExport"></export-table> -->
                <a-popconfirm title="您确定要导出该报表吗？" ok-text="确认" cancel-text="取消" @confirm="export_excel">
					<a-button class="a_btn">导出</a-button>
				</a-popconfirm>
			</template>
		</TableList>
	</div>
</template>

<script>
	import Form from "@/components/Form";
	import TableList from "@/components/TableList";
    import {costDetailed,costDetailedCensus,costDetailedExport} from "@/api/cost";

	const columns = [
		{
			title: "姓名",
			dataIndex: "username",
			scopedSlots: {
				customRender: "username"
			}
		},
		{
			title: "客户名称",
			dataIndex: "name",
			scopedSlots: {
				customRender: "name"
			}
		},
        {
			title: "待发放",
			dataIndex: "wait_grant",
			scopedSlots: {
				customRender: "wait_grant"
			}
		},
        {
			title: "待签收",
			dataIndex: "wait_write",
			scopedSlots: {
				customRender: "wait_write"
			}
		},
        {
			title: "待投放",
			dataIndex: "wait_launch",
			scopedSlots: {
				customRender: "wait_launch"
			}
		},
	];

	export default {
		components: {
			Form,
			TableList
		},
		data() {
			return {
				columns,
				page: 1,
				pagination: {
					...this.$config.pagination
				},
				loading: false,
				list: [],
                get_table_list: costDetailed,

                submit_preprocessing: {
                    array_to_string: ['department']
                },
				
                form_data_seo: {
                    ...this.$config.form_data_seo,
                    list: [
                        {
                            type: "tree-select",
                            name: "department",
                            title: "部门",
                            options: {},
                            treeData: [],
                            multiple: true
                        },
                        {
                            type: "text",
                            name: "keyword",
                            title: "姓名",
                            placeholder: '客户名称/姓名/业务员ID/手机号/客户ID',
                            mode: "default",
                            options: {},
                            list: []
                        },
                        {
                            type: "select",
                            name: "level_id",
                            title: "客户等级",
                            mode: "default",
                            options: {},
                            list: []
                        },
                        {
                            type: "tree-select",
                            name: "group_id",
                            title: "客户类型",
                            options: {},
                            treeData: [],
                            multiple: true
                        },
                        {
                            type: "select",
                            name: "status",
                            title: "客户状态",
                            mode: "default",
                            options: {},
                            list: [
                                {
                                    key: "1",
                                    value: "运营中"
                                },
                                {
                                    key: "-1",
                                    value: "封存"
                                },
                            ]
                        },
                        {
                            type: "range_date",
                            name: "range_date",
                            title: "日期",
                            options: {},
                            start: {
                                name: 'start_time'
                            },
                            end: {
                                name: 'end_time'
                            },
                        },
                    ],
                },
				form_data: {},

                statInfo:[],
			};
		},
		async created() {
            Promise.all([this.$method.get_department(), this.$method.get_group(), this.$method.get_level()]).then(res => {
                this.form_data_seo.list.forEach(item => {
                    if (item.name == "department") {
                        item.treeData = res[0];
                    }
                    if (item.name == "group_id") {
                        item.treeData = res[1];
                    }
                    if (item.name == "level_id") {
                        item.list = res[2];
                    }
                });
            });

            this.$nextTick(()=>{
                this.changeListStatNum()
            })
            
		},
		methods: {
			export_excel(){
                costDetailedExport({
                    data:{
                        ...this.$refs.list.seo_data, // 筛选项表单值
                    },
                    info:true,
                    loading:true
                })
            },
            changeListStatNum(){
                costDetailedCensus({
                    data:{
                        ...this.$refs.list.seo_data, // 筛选项表单值
                    }
                }).then(res=>{
                    this.statInfo = res.data
                })
            },
		}
	};
</script>

<style lang="less">
	@import url("../../assets/less/app.less");
</style>
